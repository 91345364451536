import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import SearchAndButtons from '../common/UI/Search&Buttons';
import OrderTable from '../components/Orders/orderTable';
import services from '../api/api';
import { isUserDoctor } from '../utils/helpers';
import deleteIcon from '../assets/images/trash.svg';
import activateIcon from '../assets/images/start.png';
import moment from 'moment';
import { toastError, toastSuccess } from '../common/UI/Toasts';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import ReactDatePicker from 'react-datepicker';
import CustomDropdown from '../common/UI/Dropdown';
import { Button } from 'react-bootstrap';

const PausedSubscriptionManagement = () => {
  const navigate = useNavigate();
  const [unpauseForm, setUnpauseForm] = useState(false)
  const [unpauseSub, setUnpauseSub] = useState()
  const [editNextDate, setEditNextDate] = useState();
  const [nextDueDate, setNextDueDate] = useState();
  const [productSelected, setProductSelected] = useState();
  const [variantOptions, setVariantOptions] = useState([]);
  const [productsOptions, setProductsOptions] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState();
  const [disableButtons, setDisableButtons] = useState(false);
  const [subAmount, setSubAmount] = useState();

  const columns = [
    {
      Header: 'Product Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.product?.name}</span>;
      },
    },
    {
      Header: 'Customer Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span>
            {`${row?.original?.user?.first_name} ${row?.original?.user?.last_name}`}
          </span>
        );
      },
    },
    {
      Header: 'Email',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: row.original.cancelled ? 'red' : 'black',
            }}
          >
            {`${row?.original?.user?.email}`}
          </span>
        );
      },
    },
    {
      Header: 'Subscription Price',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.lastorder[0].total}</span>;
      },
    },
    {
      Header: 'Start Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span>{moment(row.original.started_date).format('YYYY-MM-DD')}</span>
        );
      },
    },
    {
      Header: 'Paused Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span>{moment(row.original.paused_date).format('YYYY-MM-DD')}</span>
        );
      },
    },
    {
      Header: 'Delivery Service',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.lastorder[0]?.delivery?.title}</span>;
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span
              className="me-2"
              onClick={() => handleActivateSubscription(row)}
            >
              <img src={activateIcon} alt="activate" width="34" height="34" />
            </span>
            <button className='btn btn-primary px-2 py-1' onClick={() => handleUnpauseForm(row)}>Unpause</button>
            <span
              className="me-2"
              onClick={() => handleCancelSubscription(row)}
            >
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem('userId');
  const [csvData, setCsvData] = useState([]);
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    setLoading(true);
    getOrders(currentPageNo);
  }, [currentPageNo, seeAll, searchVal]);

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const getOrders = (pageNo, value = null) => {
    let params = seeAll
      ? { showall: true }
      : {
        perPage: 10,
        page: pageNo,
      };
    params = {
      ...params,
      paused: true,
    };
    if (searchVal)
      params = {
        ...params,
        search: searchVal,
      };

    services
      .getAllSubscriptions(params)
      .then((res) => {
        let data = res?.data?.data?.subscriptions?.docs;
        setData(data);
        loadCSVdata(data);
        setTotalPage(res?.data?.data?.subscriptions?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const handleOrderSearch = (value) => {
    setSearchVal(value);
  };

  const onSearchDebounce = debounce(handleOrderSearch, 1000);

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  const handleCancelSubscription = (rowData) => {
    services
      .cancelSubscription(rowData?.original?._id)
      .then((res) => {
        toastSuccess('Subscription Cancelled Successfully!!');
        getOrders();
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const handleActivateSubscription = (rowData) => {
    services
      .updateSubscription(rowData?.original?._id, { paused: false })
      .then((res) => {
        toastSuccess('Subscription Paused Successfully!!');
        navigate(0);
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const handleSubscription = (rowData) => {
    services
      .cancelSubscription(rowData?.original?._id)
      .then((res) => {
        toastSuccess('Subscription Cancelled Successfully!!');
        getOrders();
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const handleUnPauseSubscription = (rowData) => {
    if (nextDueDate == undefined || productSelected == undefined || selectedVariant?.value == undefined) {
      toastError('Please fill all the details')
      return
    }
    let payload = {
      next_due_date: nextDueDate,
      product_id: productSelected,
      variant_id: selectedVariant.value
    }

    if (subAmount) {
      payload = { ...payload, amount: subAmount }
    }

    services
      .unPauseSubsctiption(rowData?.original?._id, payload)
      .then((res) => {
        toastSuccess('Subscription unpaused successfully!!');
        navigate(0);
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const handleUnpauseForm = (rowData) => {
    setUnpauseForm(true)
    setUnpauseSub(rowData)
    services
      .getAllProductOptions()
      .then((res) => {
        let options = res.data.data?.map((el) => {
          return {
            label: el?.name,
            value: el?.name,
            productId: el?._id,
          };
        });
        setProductsOptions(options);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  const getProductVariant = (option) => {
    setProductSelected(option.productId);
    services
      .getVariantOptions(option.productId)
      .then((res) => {
        let options = [];
        options = res?.data?.data?.map((el) => {
          let label = Object.keys(el?.attributes).reduce((prev, curr) => {
            return prev + ` ${curr}(${el?.attributes[curr]})`;
          }, '');
          return {
            label,
            value: el?._id,
          };
        });
        setVariantOptions(options);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const CSVheaders = [
    { label: 'Product Name', key: 'product_name' },
    { label: 'Customer Name', key: 'customer_name' },
    { label: 'Customer Email', key: 'customer_email' },
    { label: 'Subscription Price', key: 'sub_price' },
    { label: 'Start Date', key: 'start_date' },
    { label: 'End Date', key: 'end_date' },
    { label: 'Delivery Service', key: 'delivery_service' },
    { label: 'Status', key: 'status' },
  ];

  const loadCSVdata = (orderdata) => {
    const modifyorderdata = orderdata?.length
      ? orderdata?.map((el) => {
        return {
          product_name: el?.product?.name,
          customer_name: `${el?.user?.first_name} ${el?.user?.last_name}`,
          customer_email: `${el?.user?.email}`,
          sub_price: el?.lastorder[0]?.total,
          start_date: moment(el?.started_date).format('YYYY-MM-DD'),
          end_date: el?.cancelled_date
            ? moment(el?.cancelled_date).format('YYYY-MM-DD')
            : '-',
          delivery_service: el?.lastorder[0]?.delivery?.title,
          status: 'Cancelled',
        };
      })
      : [];
    setCsvData(modifyorderdata);
  };
  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                {unpauseForm && (
                  <>
                    <div
                      className="header_top"
                      style={{ justifyContent: 'space-between' }}
                    >
                      <h3>Unpause Subscription Form</h3>
                    </div>
                    <div className="middle_main">
                      <div className="col-xl-4 col-md-6 col-lg-6 form-group mt-3">
                        <h6 className="available-text">
                          User Email: {unpauseSub.original.user.email}
                        </h6>
                        <h6 className="available-text">Select Next Due Date</h6>
                        <ReactDatePicker
                          onChange={(value) => {
                            setNextDueDate(value);
                          }}
                          selected={nextDueDate}
                          style={{ zIndex: 99 }}
                          className="form-control form-input px-4"
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date().setDate(new Date().getDate() + 2)}
                        />
                        <h6 className="available-text">Select Medication</h6>
                        <CustomDropdown
                          height="44px"
                          className="mx-2"
                          onChange={(value) => {
                            getProductVariant(value);
                          }}
                          border={'1 px solid #9aa5ad'}
                          options={productsOptions}
                        />
                        {productSelected ? (
                          <div
                            className="d-flex flex-column"
                            style={{
                              width: '100%',
                            }}
                          >
                            <p className="form-label">Select Variant</p>

                            <CustomDropdown
                              height="44px"
                              className="mx-2"
                              onChange={(value) => {
                                setSelectedVariant(value);
                              }}
                              border={'1 px solid #9aa5ad'}
                              options={variantOptions}
                            />
                          </div>
                        ) : null}
                        <h6 className="available-text">Amount (Not Required)</h6>

                        <input
                          placeholder='Amount'
                          className="form-control me-2  border-1 shadow-none my-2"
                          value={subAmount}
                          onChange={(e) => setSubAmount(e.target.value)}
                        />
                        <Button
                          className="slot-submit"
                          onClick={() => {
                            handleUnPauseSubscription(unpauseSub)
                          }}
                          disabled={disableButtons ? 'disabled' : ''}
                        >
                          {disableButtons ? 'Processing..' : 'Submit'}
                        </Button>

                      </div>
                    </div>
                  </>
                )}
                {!unpauseForm && (
                  <>
                    <div
                      className="header_top"
                      style={{ justifyContent: 'space-between' }}
                    >
                      <h3>Paused Subscription Management</h3>
                    </div>
                    <div className="middle_main">
                      <SearchAndButtons
                        onSearchChange={onSearchDebounce}
                        searchPlaceholder={'Search by product and user name...'}
                        buttonName={
                          <CSVLink
                            data={csvData}
                            filename={`orders_${moment().format(
                              'YYYY_MM_DD_HH_mm_ss',
                            )}.xlsx`}
                            target="_blank"
                            headers={CSVheaders}
                            style={{ color: '#fff' }}
                          >
                            Export Orders
                          </CSVLink>
                        }
                        loading={loading}
                        seeAllHandle={seeAllHandle}
                      />
                      {loading ? (
                        <Loader loading={loading} />
                      ) : (
                        <ReactTable
                          title="Subscription Orders"
                          tableColumns={columns}
                          tableData={data}
                          seeAll={seeAll}
                          nextHandle={nextHandle}
                          prevHandle={prevHandle}
                          totalPage={totalPage}
                          pageNo={currentPageNo}
                        />
                      )}
                    </div>
                  </>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PausedSubscriptionManagement;
