import user from './user';
import category from './category';
import product from './product';
import keyword from './keyword';
import banner from './banner';
import brand from './brand';
import stock from './stock';
import blog from './blog';
import featuredPords from './featuredPords';
import contact from './contact';
import generalHealth from './generalQuestions';
import orders from './orders';
import promotions from './promotions';
import couponcode from './couponcode';
import healthGoal from './healthGoal';
import offerTitle from './offerTitle';
import GPAddress from './GPAddress';
import subscription from './subscription';
import accounting from './accounting';
import SEO from './SEO';
import dashboard from './dashboard'

// import all apis here
export default {
  ...user,
  ...category,
  ...product,
  ...keyword,
  ...banner,
  ...brand,
  ...featuredPords,
  ...blog,
  ...stock,
  ...contact,
  ...generalHealth,
  ...orders,
  ...promotions,
  ...couponcode,
  ...healthGoal,
  ...offerTitle,
  ...GPAddress,
  ...subscription,
  ...accounting,
  ...SEO,
  ...dashboard,
};
